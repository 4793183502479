import { Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'utils/graphql';

interface AdpCertUploadFormProps {
  connectionId: string;
  onNextStep: VoidFunction;
}

export const AdpCertUploadForm: FC<AdpCertUploadFormProps> = ({
  connectionId,
  onNextStep: handleNextStep,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [newX509Certificate, setNewX509Certificate] = useState<
    string | undefined
  >();
  const [newAdpSslCertificate, setNewAdpSslCertificate] = useState<
    string | undefined
  >();
  const [newAdpSslPrivateKey, setNewAdpSslPrivateKey] = useState<
    string | undefined
  >();

  useEffect(() => {
    const setUpdateError = () => {
      setError('The given ADP Certificates were invalid. Please try again.');
      setNewAdpSslCertificate(undefined);
      setNewAdpSslPrivateKey(undefined);
    };

    const updateAdpConnection = async () => {
      try {
        const { data } = await graphql().AddAdpConnectionSslCertificate({
          input: {
            connectionId,
            value: newAdpSslCertificate || '',
            privateKey: newAdpSslPrivateKey || '',
          },
        });

        if (
          data &&
          data.portal_addAdpConnectionSslCertificate.__typename ===
            'Portal_AdpConnectionSslCertificateAdded'
        ) {
          setNewX509Certificate(
            data.portal_addAdpConnectionSslCertificate
              .adpConnectionSslCertificate.value,
          );

          return;
        }
      } catch (error) {
        // noop
      }

      setUpdateError();
    };

    if (newAdpSslPrivateKey && newAdpSslCertificate) {
      void updateAdpConnection();
    }
  }, [connectionId, newAdpSslPrivateKey, newAdpSslCertificate]);

  return (
    <>
      {error && (
        <div className="mt-4 self-start text-red-darken">
          <Text inheritColor as="p">
            {error}
          </Text>
        </div>
      )}
      <Card>
        <Form
          disabled={!newX509Certificate || !!error}
          isUpdate={false}
          onSubmit={handleNextStep}
        >
          <FileField
            key={newAdpSslCertificate}
            accept=".pem"
            id="adp-ssl-cert"
            label="Add an ADP SSL Certificate"
            name="newAdpSslCertificate"
            onUpload={({ file }) => {
              setError(undefined);
              setNewAdpSslCertificate(file.content);
            }}
            value={newAdpSslCertificate}
          />

          <FileField
            key={newAdpSslPrivateKey}
            accept=".key"
            id="adp-private-key"
            label="Add an ADP SSL Private Key"
            name="newAdpSslPrivateKey"
            onUpload={({ file }) => {
              setError(undefined);
              setNewAdpSslPrivateKey(file.content);
            }}
            value={newAdpSslPrivateKey}
          />
        </Form>
      </Card>
    </>
  );
};
